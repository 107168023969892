
// import { breakpoints } from '../variables.yml';

// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';

import './vendor/ajax.min.js';
import EmblaCarousel from 'embla-carousel';
import { getWindowWidth } from './helpers.js';
import { LoadOMatic } from './vendor/loadomatic.js';

/* eslint-disable snakecasejs/snakecasejs */
export * from './CookieConsentManager.js';

( () => {
	// Nav search toggle
	const search_open = document.querySelector( '.nav__search' );
	const search_close = document.querySelector( '.search__close' );

	search_open.addEventListener( 'click', toggleSearch );
	search_close.addEventListener( 'click', toggleSearch );

	function toggleSearch( e ) {
		e.preventDefault();
		const body_classes = document.body.classList;
		if ( body_classes.contains( 'search-open' ) ) {
			body_classes.remove( 'search-open' );
		} else {
			body_classes.add( 'search-open' );
			document.getElementById( 'search' ).focus();
		}
	}
} )();

( () => {
	// Toggle nav
	const toggle = document.querySelector( '.nav__menu' );
	toggle.addEventListener( 'click', () => {
		if ( document.body.classList.contains( 'nav-open' ) ) {
			document.body.classList.remove( 'nav-open' );
			toggle.setAttribute( 'aria-label', 'Open menu' );
		} else {
			document.body.classList.add( 'nav-open' );
			toggle.setAttribute( 'aria-label', 'Close menu' );
		}
	} );

	window.addEventListener( 'resize', () => {
		if ( getWindowWidth() > 1100 ) {
			document.body.classList.remove( 'nav-open' );
		}
	} );
} )();

( () => {
	// Video embeds

	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	window.addEventListener( 'click', ( e ) => {
		const video = isAVideo( e.target );
		if ( !video ) {
			return;
		}
		e.preventDefault();
		buildVideoPlayer( video );
	} );

	function isAVideo( el ) {
		let node = el;
		while ( node !== null
		&& node !== undefined
		&& node.classList !== undefined ) {
			if ( node.classList.contains( 'video-embed' ) ) {
				return node;
			}
			node = node.parentNode;
		}
		return false;
	}

} )();

( () => {
	// Accordion
	const accordion = document.querySelectorAll( '.accordion__button' );
	for ( let i = 0; i < accordion.length; i++ ) {
		accordion[i].addEventListener( 'click', function() {
			this.classList.toggle( 'active' );

			const panel = this.nextElementSibling;
			if ( panel.style.maxHeight ) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = panel.scrollHeight + 'px';
			}
		} );
	}
} )();

( () => {
	// Embla carousel
	const el = document.querySelector( '.embla__container' );
	if ( !el ) {
		return;
	}

	const options = JSON.parse( el.getAttribute( 'data-options' ) )[0];

	let embla = null;

	const desktop_only = el.getAttribute( 'data-desktop-only' );
	const mobile_only = el.getAttribute( 'data-mobile-only' );

	if ( mobile_only ) {
		checkEmbla( 'mobile-only' );
		window.addEventListener( 'resize', () => {
			checkEmbla( 'mobile-only' );
		} );
	} else if ( desktop_only ) {
		checkEmbla( 'desktop-only' );
		window.addEventListener( 'resize', () => {
			checkEmbla( 'desktop-only' );
		} );
	} else {
		initEmbla();
	}

	function checkEmbla( when_to_show ) {
		const query = when_to_show === 'mobile-only' ? getWindowWidth() < 769 : getWindowWidth() > 769;
		if ( query && when_to_show === 'mobile-only' ) {
			if ( embla === null ) {
				initEmbla();
			}
		} else if ( query && when_to_show === 'desktop-only' ) {
			if ( embla === null ) {
				embla = EmblaCarousel( el, options );
				const dots_array = Array.prototype.slice.call( document.querySelectorAll( '.embla__pip' ) );
				const slides = Array.prototype.slice.call( document.querySelectorAll( '.embla__slide' ) );
				const set_selected_dot_btn = selectDotButton( dots_array, embla );
				const autoplayer = autoPlay( embla, 5000 );
				setupGoToNext( slides, embla );

				embla.on( 'select', set_selected_dot_btn );
				embla.on( 'init', set_selected_dot_btn );
				autoplayer.play();
			}
		} else {
			if ( embla !== null ) {
				const autoplayer = autoPlay( embla, 5000 );
				autoplayer.stop();
				embla.destroy();
				embla = null;
			}
		}
	}

	function initEmbla() {
		embla = EmblaCarousel( el, options );
		const dots = document.querySelector( '.embla__dots' );
		const dots_array = generateDotButtons( dots, embla );
		const set_selected_dot_btn = selectDotButton( dots_array, embla );
		setupDotButtons( dots_array, embla );

		embla.on( 'select', set_selected_dot_btn );
		embla.on( 'init', set_selected_dot_btn );
	}

	function generateDotButtons( dots, embla ) {
		const scroll_snaps = embla.scrollSnapList();
		const dots_frag = document.createDocumentFragment();
		const dots_array = scroll_snaps.map( () => document.createElement( 'button' ) );
		dots_array.forEach( dot_node => dots_frag.appendChild( dot_node ) );
		dots.appendChild( dots_frag );
		return dots_array;
	}

	function setupGoToNext( slides, embla ) {
		slides.forEach( ( slide ) => {
			slide.addEventListener( 'click', () => {
				embla.scrollNext();
			}, false );
		} );
	}

	function setupDotButtons( dots_array, embla ) {
		dots_array.forEach( ( dot_node, i ) => {
			dot_node.classList.add( 'embla__dot' );
			dot_node.addEventListener( 'click', () => embla.scrollTo( i ), false );
		} );
	}

	function selectDotButton( dots_array, embla ) {
		return function() {
			const prev = embla.previousScrollSnap();
			const selected = embla.selectedScrollSnap();
			dots_array[prev].classList.remove( 'is-selected' );
			dots_array[selected].classList.add( 'is-selected' );
		};
	}

	function autoPlay( embla, interval ) {
		const state = { timer: 0 };

		const play = () => {
			stop();
			requestAnimationFrame( () => ( state.timer = window.setTimeout( next, interval ) ) );
		};

		const stop = () => {
			window.clearTimeout( state.timer );
			state.timer = 0;
		};

		const next = () => {
			if ( embla.canScrollNext() ) {
				embla.scrollNext();
			} else {
				embla.scrollTo( 0 );
			}
			play();
		};

		return { play, stop };
	}
} )();

( () => {
	const loadomatic_container = document.querySelector( '.loadomatic' );
	if ( !loadomatic_container ) {
		return;
	}

	const current_page = parseInt( loadomatic_container.getAttribute( 'data-current-page' ) );
	const total_pages = parseInt( loadomatic_container.getAttribute( 'data-total-pages' ) );
	const extra_query = loadomatic_container.getAttribute( 'data-extra-query' );
	const url = window.location.href.split( '?' )[0];

	new LoadOMatic( {
		loadUrl: url,
		currentPage: current_page,
		totalPages: total_pages,
		extraQuery: extra_query
	} );

} )();

( () => {
	// Smooth scroll
	const anchors = Array.prototype.slice.call( document.querySelectorAll( '.scroll' ) );
	if ( !anchors ) {
		return;
	}

	anchors.forEach( anchor => {
		anchor.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			const el_id = e.target.href.split( '#' )[ 1 ];
			const el = document.getElementById( el_id );

			// eslint-disable-next-line no-unused-vars
			const anim = requestAnimationFrame( ( timestamp ) => {
				const stamp = timestamp || new Date().getTime;
				const duration = 400;
				const start = stamp;
				const start_scroll_offset = window.pageYOffset;
				// 49 is the nav height
				const scroll_end_el_top = el.getBoundingClientRect().top - 40;

				scrollTo( start, stamp, duration, scroll_end_el_top, start_scroll_offset );

			} );

			const easeInCubic = function( t ) {
				return t*t*t;
			};
			const scrollTo = ( start_time, current_time, duration, scroll_end_el_top, start_scroll_offset ) => {
				const runtime = current_time - start_time;
				let progress = runtime / duration;

				progress = Math.min( progress, 1 );
				const ease = easeInCubic( progress );
				window.scroll( 0, start_scroll_offset + ( scroll_end_el_top * ease ) );
				if ( runtime < duration ) {
					requestAnimationFrame( ( timestamp ) => {
						const current_time = timestamp || new Date().getTime();
						scrollTo( start_time, current_time, duration, scroll_end_el_top, start_scroll_offset );
					} );
				}

			};

		} );
	} );
} )();

( () => {
	// Image slide
	const slide_container = document.querySelector( '.slide-image__container' );
	if ( !slide_container ) {
		return;
	}

	const buttons = Array.prototype.slice.call( document.querySelectorAll( '.slide-image__arrow' ) );
	// const slide_right = document.querySelectorAll( '.slide-image__arrow--right' );
	buttons.forEach( button => {
		button.addEventListener( 'click', e => {
			const container = e.target.parentElement.nextElementSibling.children[0];
			if ( button.classList.contains( 'slide-image__arrow--left' ) ) {
				sideScroll( container, 'right', 10, 600, 10 );
			} else {
				sideScroll( container, 'left', 10, 600, 10 );
			}
		} );
	} );
	buttons.forEach( button => {
		button.addEventListener( 'keypress', ( e ) => {
			if ( e.key === 'Enter' ) {
				const container = e.target.parentElement.nextElementSibling.children[0];
				if ( button.classList.contains( 'slide-image__arrow--left' ) ) {
					sideScroll( container, 'right', 10, 600, 10 );
				} else {
					sideScroll( container, 'left', 10, 600, 10 );
				}
			}
		} );
	} );

	function sideScroll( element, direction, speed, distance, step ) {
		let scroll_amount = 0;
		const slide_timer = setInterval( () => {
			if ( direction === 'left' ) {
				element.scrollLeft -= step;
			} else {
				element.scrollLeft += step;
			}
			scroll_amount += step;
			if ( scroll_amount >= distance ) {
				window.clearInterval( slide_timer );
			}
		}, speed );
	}

} )();
